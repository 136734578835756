var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c(
              "h3",
              {
                staticClass: "tit-group-bg mt-10",
                staticStyle: { color: "rgba(0, 0, 0, 0.6)", "line-height": "1" }
              },
              [_vm._v(" " + _vm._s(_vm.$t("Dashboard Language")) + " ")]
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        [
          _c("p", { staticClass: "label-txt" }, [
            _vm._v(" " + _vm._s(_vm.$t("Language List")) + " ")
          ]),
          _c(
            "v-row",
            { staticStyle: { padding: "0" } },
            [
              _c("v-col", { staticStyle: { "max-width": "280px" } }, [
                _c(
                  "div",
                  {
                    staticClass: "custom-scrollBox",
                    staticStyle: {
                      overflow: "scroll",
                      height: "250px",
                      "padding-right": "0px"
                    }
                  },
                  [
                    _c(
                      "ul",
                      {
                        staticClass: "label-txt",
                        staticStyle: { "padding-left": "0px" }
                      },
                      _vm._l(_vm.languagelist, function(language, index) {
                        return _c(
                          "li",
                          {
                            key: language.id,
                            class: { active: _vm.activeIndex === index },
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function($event) {
                                return _vm.setActive(language, index)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(language.languageName) + " ")]
                        )
                      }),
                      0
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "mt-5" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-s",
                        attrs: {
                          disabled: _vm.btnDisabledDashboardSettings,
                          text: ""
                        },
                        on: { click: _vm.Addnewlanguage }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Add")) + " ")]
                    )
                  ],
                  1
                )
              ]),
              _c("v-col", { staticStyle: { "max-width": "10px" } }),
              _c(
                "v-col",
                { staticClass: "languagesetting" },
                [
                  _c("p", { staticClass: "label-txt" }, [
                    _vm._v(" " + _vm._s(_vm.$t("Language Setting")) + " ")
                  ]),
                  _c("br"),
                  _c("v-row", { staticStyle: { "padding-left": "13px" } }, [
                    _c("div", [
                      _c("p", { staticClass: "label-txt" }, [
                        _vm._v(" " + _vm._s(_vm.$t("Language")) + " ")
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "justify-content": "normal",
                            display: "inline-flex"
                          }
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-text-field", {
                                staticClass: "form-input appendIcon",
                                staticStyle: {
                                  "max-width": "100px",
                                  "margin-left": "10px"
                                },
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                  solo: "",
                                  placeholder: "" + _vm.$t("title"),
                                  disabled:
                                    (_vm.defaultLanguageenable &&
                                      !_vm.addbutton) ||
                                    _vm.currentlanguagenamevalue,
                                  type: "text",
                                  clearable: ""
                                },
                                on: { keydown: _vm.clearTabindex },
                                model: {
                                  value: _vm.language_Name,
                                  callback: function($$v) {
                                    _vm.language_Name = $$v
                                  },
                                  expression: "language_Name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    !_vm.addbutton
                      ? _c("div", { staticStyle: { "margin-left": "30px" } }, [
                          _c("p", { staticClass: "label-txt" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("Current Language File")) +
                                " "
                            )
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "justify-content": "normal",
                                display: "inline-flex"
                              }
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-text-field", {
                                    staticClass: "form-input appendIcon",
                                    staticStyle: {
                                      "max-width": "250px",
                                      "margin-left": "10px"
                                    },
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "",
                                      solo: "",
                                      type: "text",
                                      disabled:
                                        (_vm.defaultLanguageenable &&
                                          !_vm.addbutton) ||
                                        _vm.currentlanguagenamevalue,
                                      clearable: ""
                                    },
                                    on: { keydown: _vm.clearTabindex },
                                    model: {
                                      value: _vm.langfileName,
                                      callback: function($$v) {
                                        _vm.langfileName = $$v
                                      },
                                      expression: "langfileName"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      class: {
                                        disabledSpan:
                                          _vm.btnDisabledDashboardSettings
                                      },
                                      staticStyle: { "margin-left": "10px" },
                                      on: {
                                        click: function($event) {
                                          return _vm.downloadfile()
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        class: {
                                          disabled:
                                            _vm.btnDisabledDashboardSettings,
                                          downloadicon: !_vm.btnDisabledDashboardSettings
                                        },
                                        attrs: {
                                          width: "24",
                                          height: "24",
                                          src: require("@/assets/img/download_icon.png")
                                        }
                                      })
                                    ]
                                  ),
                                  _c("a", {
                                    ref: "link",
                                    style: { display: "none" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticStyle: { "margin-left": "30px" } }, [
                      _c("p", { staticClass: "label-txt" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("Language File Import")) + " "
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "fileinput" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticStyle: { cursor: "pointer" } },
                                [
                                  _c("v-file-input", {
                                    ref: "inputFile",
                                    staticClass: "form-file",
                                    staticStyle: {
                                      width: "200px",
                                      cursor: "pointer"
                                    },
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "",
                                      "prepend-icon": "",
                                      solo: "",
                                      flat: "",
                                      disabled:
                                        _vm.btnDisabledDashboardSettings,
                                      placeholder: "" + _vm.$t("File Upload")
                                    },
                                    on: { change: _vm.onChange },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "append",
                                        fn: function() {
                                          return [
                                            _c("img", {
                                              staticStyle: {
                                                cursor: "pointer"
                                              },
                                              attrs: {
                                                width: "24",
                                                height: "24",
                                                src: require("@/assets/img/ico-upload-file.gif")
                                              }
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ]),
                                    model: {
                                      value: _vm.chosenFile,
                                      callback: function($$v) {
                                        _vm.chosenFile = $$v
                                      },
                                      expression: "chosenFile"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.addbutton
                                ? _c(
                                    "div",
                                    { staticClass: "mt-3" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn-m",
                                          staticStyle: {
                                            background: "#001e38",
                                            color: "#fff",
                                            cursor: "pointer"
                                          },
                                          attrs: { text: "" },
                                          on: { click: _vm.downloadguidefile }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("Download Guide File")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("a", {
                                ref: "link1",
                                style: { display: "none" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ]),
                  _c(
                    "v-row",
                    {
                      staticStyle: { "margin-left": "0px" },
                      attrs: { justify: "start" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-5" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-s",
                              attrs: {
                                text: "",
                                disabled:
                                  _vm.chosenFile === null ||
                                  _vm.chosenFile === undefined ||
                                  _vm.btnDisabledDashboardSettings
                              },
                              on: { click: _vm.toSavelanguageContent }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
                          )
                        ],
                        1
                      ),
                      !_vm.addbutton
                        ? _c(
                            "div",
                            { staticClass: "mt-5 ml-2" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-s",
                                  attrs: {
                                    disabled: _vm.btnDisabledDashboardSettings,
                                    text: ""
                                  },
                                  on: { click: _vm.toRestlanguageContent }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("Reset")) + " ")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "mt-5 ml-2" },
                        [
                          !_vm.defaultLanguageenable && !_vm.addbutton
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "btn-s",
                                  attrs: { text: "" },
                                  on: { click: _vm.Todeletelanguagelist }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }