var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c(
              "h3",
              {
                staticClass: "tit-group-bg mt-10",
                staticStyle: { color: "rgba(0, 0, 0, 0.6)", "line-height": "1" }
              },
              [_vm._v(" " + _vm._s(_vm.$t("Software Image Custom")) + " ")]
            )
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "label-txt", staticStyle: { width: "100%" } },
        [
          _c(
            "div",
            {
              staticStyle: {
                "justify-content": "normal",
                display: "inline-flex"
              }
            },
            [
              _c(
                "v-col",
                { staticStyle: { "margin-top": "12px" }, attrs: { cols: "3" } },
                [_vm._v(" " + _vm._s(_vm.$t("Login Main Image")))]
              ),
              _c(
                "v-col",
                { staticStyle: { "margin-top": "12px" }, attrs: { cols: "3" } },
                [_vm._v(" Size(px) 960 x 937")]
              ),
              _c(
                "v-col",
                { staticStyle: { "margin-top": "12px" }, attrs: { cols: "3" } },
                [_vm._v(" *.jpg,.png,.gif")]
              ),
              _c(
                "v-col",
                { staticStyle: { "margin-left": "-3px" } },
                [
                  _c("v-file-input", {
                    staticClass: "form-file",
                    staticStyle: { width: "200px" },
                    attrs: {
                      accept: "image/png, image/jpeg, image/gif",
                      multiple: false,
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      "prepend-icon": "",
                      solo: "",
                      flat: "",
                      placeholder: "" + _vm.$t("File Upload"),
                      clearable: false
                    },
                    on: {
                      change: function($event) {
                        return _vm.updateCustomerImage(1, "update")
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append",
                        fn: function() {
                          return [
                            _vm.CustomImage1 != ""
                              ? _c(
                                  "v-icon",
                                  {
                                    staticStyle: { color: "red" },
                                    attrs: { medium: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.updateCustomerImage(
                                          1,
                                          "delete"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("mdi-close-circle ")]
                                )
                              : _vm._e(),
                            _vm.CustomImage1 === ""
                              ? _c("span", [
                                  _c("img", {
                                    attrs: {
                                      width: "24",
                                      height: "24",
                                      src: require("@/assets/img/ico-upload-file.gif")
                                    }
                                  })
                                ])
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.ImageInput.file_1,
                      callback: function($$v) {
                        _vm.$set(_vm.ImageInput, "file_1", $$v)
                      },
                      expression: "ImageInput.file_1"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "v-row",
        { staticClass: "label-txt", staticStyle: { width: "100%" } },
        [
          _c(
            "div",
            {
              staticStyle: {
                "justify-content": "normal",
                display: "inline-flex"
              }
            },
            [
              _c(
                "v-col",
                { staticStyle: { "margin-top": "12px" }, attrs: { cols: "3" } },
                [_vm._v(" " + _vm._s(_vm.$t("Login Logo Image")))]
              ),
              _c(
                "v-col",
                { staticStyle: { "margin-top": "12px" }, attrs: { cols: "3" } },
                [_vm._v(" Size(px) 148 x 26")]
              ),
              _c(
                "v-col",
                { staticStyle: { "margin-top": "12px" }, attrs: { cols: "3" } },
                [_vm._v(" *.jpg,.png,.gif")]
              ),
              _c(
                "v-col",
                [
                  _c("v-file-input", {
                    staticClass: "form-file",
                    staticStyle: { width: "200px" },
                    attrs: {
                      accept: "image/png, image/jpeg, image/gif",
                      multiple: false,
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      "prepend-icon": "",
                      solo: "",
                      flat: "",
                      placeholder: "" + _vm.$t("File Upload"),
                      clearable: false
                    },
                    on: {
                      change: function($event) {
                        return _vm.updateCustomerImage(2, "update")
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append",
                        fn: function() {
                          return [
                            _vm.CustomImage2 != ""
                              ? _c(
                                  "v-icon",
                                  {
                                    staticStyle: { color: "red" },
                                    attrs: { medium: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.updateCustomerImage(
                                          2,
                                          "delete"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("mdi-close-circle ")]
                                )
                              : _vm._e(),
                            _vm.CustomImage2 === ""
                              ? _c("span", [
                                  _c("img", {
                                    attrs: {
                                      width: "24",
                                      height: "24",
                                      src: require("@/assets/img/ico-upload-file.gif")
                                    }
                                  })
                                ])
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.ImageInput.file_2,
                      callback: function($$v) {
                        _vm.$set(_vm.ImageInput, "file_2", $$v)
                      },
                      expression: "ImageInput.file_2"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "v-row",
        { staticClass: "label-txt", staticStyle: { width: "100%" } },
        [
          _c(
            "div",
            {
              staticStyle: {
                "justify-content": "normal",
                display: "inline-flex"
              }
            },
            [
              _c(
                "v-col",
                { staticStyle: { "margin-top": "12px" }, attrs: { cols: "3" } },
                [_vm._v(_vm._s(_vm.$t("Menu Logo Image")))]
              ),
              _c(
                "v-col",
                { staticStyle: { "margin-top": "12px" }, attrs: { cols: "3" } },
                [_vm._v(" Size(px) 183 x 50")]
              ),
              _c(
                "v-col",
                { staticStyle: { "margin-top": "12px" }, attrs: { cols: "3" } },
                [_vm._v(" *.jpg,.png,.gif")]
              ),
              _c(
                "v-col",
                [
                  _c("v-file-input", {
                    staticClass: "form-file",
                    staticStyle: { width: "200px" },
                    attrs: {
                      accept: "image/png, image/jpeg, image/gif",
                      multiple: false,
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      "prepend-icon": "",
                      solo: "",
                      flat: "",
                      placeholder: "" + _vm.$t("File Upload"),
                      clearable: false
                    },
                    on: {
                      change: function($event) {
                        return _vm.updateCustomerImage(3, "update")
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append",
                        fn: function() {
                          return [
                            _vm.CustomImage3 != ""
                              ? _c(
                                  "v-icon",
                                  {
                                    staticStyle: { color: "red" },
                                    attrs: { medium: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.updateCustomerImage(
                                          3,
                                          "delete"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("mdi-close-circle ")]
                                )
                              : _vm._e(),
                            _vm.CustomImage3 === ""
                              ? _c("span", [
                                  _c("img", {
                                    attrs: {
                                      width: "24",
                                      height: "24",
                                      src: require("@/assets/img/ico-upload-file.gif")
                                    }
                                  })
                                ])
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.ImageInput.file_3,
                      callback: function($$v) {
                        _vm.$set(_vm.ImageInput, "file_3", $$v)
                      },
                      expression: "ImageInput.file_3"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("v-row", [
        _c(
          "div",
          { staticClass: "mt-5" },
          [
            _c(
              "v-btn",
              {
                staticClass: "btn-s ml-2",
                attrs: {
                  disabled:
                    _vm.saveBtnDisabled || _vm.btnDisabledDashboardSettings,
                  text: ""
                },
                on: {
                  click: function($event) {
                    return _vm.saveCustomerImages()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("Save")))]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }