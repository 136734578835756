var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-system" }),
        _vm._v(_vm._s(_vm.$t("Dashboard Settings")) + " ")
      ]),
      _c("br"),
      _c(
        "v-card",
        { staticStyle: { "border-radius": "10px" } },
        [
          _c("v-toolbar", {
            attrs: { flat: "" },
            scopedSlots: _vm._u([
              {
                key: "extension",
                fn: function() {
                  return [
                    _c(
                      "v-tabs",
                      {
                        staticStyle: { "margin-top": "-135px" },
                        attrs: { grow: "" },
                        model: {
                          value: _vm.tab,
                          callback: function($$v) {
                            _vm.tab = $$v
                          },
                          expression: "tab"
                        }
                      },
                      [
                        _c("v-tabs-slider"),
                        _c(
                          "v-tab",
                          {
                            staticClass: "primary--text tab-divider",
                            staticStyle: { "border-top-left-radius": "10px" },
                            attrs: { href: "#tab-1st" }
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("Alert Setting")))
                            ])
                          ]
                        ),
                        !_vm.SingleUser
                          ? _c(
                              "v-tab",
                              {
                                staticClass: "primary--text tab-divider",
                                attrs: { href: "#tab-2nd" }
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("Area Group Management"))
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "v-tab",
                          {
                            staticClass: "primary--text tab-divider",
                            attrs: { href: "#tab-3rd" }
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("REPORT")))])]
                        ),
                        _c(
                          "v-tab",
                          {
                            staticClass: "primary--text",
                            staticStyle: { "border-top-right-radius": "10px" },
                            attrs: { href: "#tab-4th" }
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("DEFAULT SET")))])]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c(
            "v-tabs-items",
            {
              staticStyle: { padding: "40px" },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _vm.tab === "tab-1st"
                ? _c(
                    "v-tab-item",
                    {
                      attrs: {
                        value: "tab-1st",
                        transition: false,
                        "reverse-transition": false
                      }
                    },
                    [
                      _c("v-card", { attrs: { flat: "" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "settingBox",
                            staticStyle: {
                              "margin-top": "-96px",
                              border: "none",
                              "max-width": "100%"
                            }
                          },
                          [
                            _c(
                              "v-row",
                              { staticStyle: { "margin-top": "-25px" } },
                              [
                                _c("v-col", [
                                  _c(
                                    "h3",
                                    {
                                      staticClass: "tit-group-bg mt-7",
                                      staticStyle: {
                                        color: "rgba(0, 0, 0, 0.6)",
                                        "line-height": "1"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Label Status")))]
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "padding-left": "20px" } },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "label-txt mt-4" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("Update Failure"))
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "innerBox",
                                          staticStyle: {
                                            "justify-content": "normal"
                                          }
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "warning cautionclass"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("Caution")) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c("v-text-field", {
                                            staticClass:
                                              "form-input appendIcon",
                                            staticStyle: {
                                              "max-width": "88px"
                                            },
                                            attrs: {
                                              outlined: "",
                                              dense: "",
                                              "hide-details": "",
                                              solo: "",
                                              suffix: "%",
                                              type: "number",
                                              min: "1",
                                              max: "100",
                                              oninput:
                                                "if(this.value>100){this.value=max;}else if(this.value=='0'){this.value=min;}",
                                              clearable: ""
                                            },
                                            on: {
                                              keypress: _vm.handleKeyUp,
                                              keydown: _vm.clearTabindex
                                            },
                                            model: {
                                              value: _vm.labelUpdatedCaution,
                                              callback: function($$v) {
                                                _vm.labelUpdatedCaution = $$v
                                              },
                                              expression: "labelUpdatedCaution"
                                            }
                                          }),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "error dangerclass"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("Danger")) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c("v-text-field", {
                                            staticClass:
                                              "form-input appendIcon",
                                            staticStyle: {
                                              "max-width": "88px"
                                            },
                                            attrs: {
                                              outlined: "",
                                              dense: "",
                                              "hide-details": "",
                                              solo: "",
                                              suffix: "%",
                                              type: "number",
                                              min: "1",
                                              max: "100",
                                              oninput:
                                                "if(this.value>100){this.value=max;}else if(this.value=='0'){this.value=min;}",
                                              clearable: ""
                                            },
                                            on: {
                                              keypress: _vm.handleKeyUp,
                                              keydown: _vm.clearTabindex
                                            },
                                            model: {
                                              value: _vm.labelUpdatedDanger,
                                              callback: function($$v) {
                                                _vm.labelUpdatedDanger = $$v
                                              },
                                              expression: "labelUpdatedDanger"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass: "tit-group-bg mt-7",
                                        staticStyle: {
                                          color: "rgba(0, 0, 0, 0.6)",
                                          "line-height": "1"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("Exception Status"))
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        _c("v-col", [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "padding-left": "20px"
                                              }
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-txt" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("Label Offline")
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "innerBox",
                                                  staticStyle: {
                                                    "justify-content": "normal"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "warning cautionclass"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t("Caution")
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c("v-text-field", {
                                                    staticClass:
                                                      "form-input appendIcon",
                                                    staticStyle: {
                                                      "max-width": "88px"
                                                    },
                                                    attrs: {
                                                      outlined: "",
                                                      dense: "",
                                                      "hide-details": "",
                                                      solo: "",
                                                      suffix: "%",
                                                      type: "number",
                                                      min: "1",
                                                      max: "100",
                                                      oninput:
                                                        "if(this.value>100){this.value=max;}else if(this.value=='0'){this.value=min;}",
                                                      clearable: ""
                                                    },
                                                    on: {
                                                      keypress: _vm.handleKeyUp,
                                                      keydown: _vm.clearTabindex
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.labelOfflineCaution,
                                                      callback: function($$v) {
                                                        _vm.labelOfflineCaution = $$v
                                                      },
                                                      expression:
                                                        "labelOfflineCaution"
                                                    }
                                                  }),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "error dangerclass"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t("Danger")
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c("v-text-field", {
                                                    staticClass:
                                                      "form-input appendIcon",
                                                    staticStyle: {
                                                      "max-width": "88px"
                                                    },
                                                    attrs: {
                                                      outlined: "",
                                                      dense: "",
                                                      "hide-details": "",
                                                      solo: "",
                                                      suffix: "%",
                                                      type: "number",
                                                      min: "1",
                                                      max: "100",
                                                      oninput:
                                                        "if(this.value>100){this.value=max;}else if(this.value=='0'){this.value=min;}",
                                                      clearable: ""
                                                    },
                                                    on: {
                                                      keypress: _vm.handleKeyUp,
                                                      keydown: _vm.clearTabindex
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.labelOfflineDanger,
                                                      callback: function($$v) {
                                                        _vm.labelOfflineDanger = $$v
                                                      },
                                                      expression:
                                                        "labelOfflineDanger"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("v-col", [
                                          _c("div", [
                                            _c(
                                              "p",
                                              { staticClass: "label-txt" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("Battery Replace")
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "innerBox",
                                                staticStyle: {
                                                  "justify-content": "normal"
                                                }
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "warning cautionclass"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("Caution")
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _c("v-text-field", {
                                                  staticClass:
                                                    "form-input appendIcon",
                                                  staticStyle: {
                                                    "max-width": "88px"
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    dense: "",
                                                    "hide-details": "",
                                                    solo: "",
                                                    suffix: "%",
                                                    type: "number",
                                                    min: "1",
                                                    max: "100",
                                                    oninput:
                                                      "if(this.value>100){this.value=max;}else if(this.value=='0'){this.value=min;}",
                                                    clearable: ""
                                                  },
                                                  on: {
                                                    keypress: _vm.handleKeyUp,
                                                    keydown: _vm.clearTabindex
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.labelLowBatteryCaution,
                                                    callback: function($$v) {
                                                      _vm.labelLowBatteryCaution = $$v
                                                    },
                                                    expression:
                                                      "labelLowBatteryCaution"
                                                  }
                                                }),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "error dangerclass"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("Danger")
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _c("v-text-field", {
                                                  staticClass:
                                                    "form-input appendIcon",
                                                  staticStyle: {
                                                    "max-width": "88px"
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    dense: "",
                                                    "hide-details": "",
                                                    solo: "",
                                                    suffix: "%",
                                                    type: "number",
                                                    min: "1",
                                                    max: "100",
                                                    oninput:
                                                      "if(this.value>100){this.value=max;}else if(this.value=='0'){this.value=min;}",
                                                    clearable: ""
                                                  },
                                                  on: {
                                                    keypress: _vm.handleKeyUp,
                                                    keydown: _vm.clearTabindex
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.labelLowBatteryDanger,
                                                    callback: function($$v) {
                                                      _vm.labelLowBatteryDanger = $$v
                                                    },
                                                    expression:
                                                      "labelLowBatteryDanger"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ])
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "pt-10" },
                                      [
                                        _c("v-col", [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "padding-left": "20px"
                                              }
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label-txt" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("Bad Signal"))
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "innerBox",
                                                  staticStyle: {
                                                    "justify-content": "normal"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "warning cautionclass"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t("Caution")
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c("v-text-field", {
                                                    staticClass:
                                                      "form-input appendIcon",
                                                    staticStyle: {
                                                      "max-width": "88px"
                                                    },
                                                    attrs: {
                                                      outlined: "",
                                                      dense: "",
                                                      "hide-details": "",
                                                      solo: "",
                                                      suffix: "%",
                                                      type: "number",
                                                      min: "1",
                                                      max: "100",
                                                      oninput:
                                                        "if(this.value>100){this.value=max;}else if(this.value=='0'){this.value=min;}",
                                                      clearable: ""
                                                    },
                                                    on: {
                                                      keypress: _vm.handleKeyUp,
                                                      keydown: _vm.clearTabindex
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.labelSignalCaution,
                                                      callback: function($$v) {
                                                        _vm.labelSignalCaution = $$v
                                                      },
                                                      expression:
                                                        "labelSignalCaution"
                                                    }
                                                  }),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "error dangerclass"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t("Danger")
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c("v-text-field", {
                                                    staticClass:
                                                      "form-input appendIcon",
                                                    staticStyle: {
                                                      "max-width": "88px"
                                                    },
                                                    attrs: {
                                                      outlined: "",
                                                      dense: "",
                                                      "hide-details": "",
                                                      solo: "",
                                                      suffix: "%",
                                                      type: "number",
                                                      min: "1",
                                                      max: "100",
                                                      oninput:
                                                        "if(this.value>100){this.value=max;}else if(this.value=='0'){this.value=min;}",
                                                      clearable: ""
                                                    },
                                                    on: {
                                                      keypress: _vm.handleKeyUp,
                                                      keydown: _vm.clearTabindex
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.labelSignalDanger,
                                                      callback: function($$v) {
                                                        _vm.labelSignalDanger = $$v
                                                      },
                                                      expression:
                                                        "labelSignalDanger"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("v-col", [
                                          _c("div", [
                                            _c(
                                              "p",
                                              { staticClass: "label-txt" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Misused"))
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "innerBox",
                                                staticStyle: {
                                                  "justify-content": "normal"
                                                }
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "warning cautionclass"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("Caution")
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _c("v-text-field", {
                                                  staticClass:
                                                    "form-input appendIcon",
                                                  staticStyle: {
                                                    "max-width": "88px"
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    dense: "",
                                                    "hide-details": "",
                                                    solo: "",
                                                    suffix: "%",
                                                    type: "number",
                                                    min: "1",
                                                    max: "100",
                                                    oninput:
                                                      "if(this.value>100){this.value=max;}else if(this.value=='0'){this.value=min;}",
                                                    clearable: ""
                                                  },
                                                  on: {
                                                    keypress: _vm.handleKeyUp,
                                                    keydown: _vm.clearTabindex
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.labelMisusedCaution,
                                                    callback: function($$v) {
                                                      _vm.labelMisusedCaution = $$v
                                                    },
                                                    expression:
                                                      "labelMisusedCaution"
                                                  }
                                                }),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "error dangerclass"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("Danger")
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _c("v-text-field", {
                                                  staticClass:
                                                    "form-input appendIcon",
                                                  staticStyle: {
                                                    "max-width": "88px"
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    dense: "",
                                                    "hide-details": "",
                                                    solo: "",
                                                    suffix: "%",
                                                    type: "number",
                                                    min: "1",
                                                    max: "100",
                                                    oninput:
                                                      "if(this.value>100){this.value=max;}else if(this.value=='0'){this.value=min;}",
                                                    clearable: ""
                                                  },
                                                  on: {
                                                    keypress: _vm.handleKeyUp,
                                                    keydown: _vm.clearTabindex
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.labelMisusedDanger,
                                                    callback: function($$v) {
                                                      _vm.labelMisusedDanger = $$v
                                                    },
                                                    expression:
                                                      "labelMisusedDanger"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ])
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c("v-col", [
                                  _c(
                                    "h3",
                                    {
                                      staticClass: "tit-group-bg mt-7",
                                      staticStyle: {
                                        color: "rgba(0, 0, 0, 0.6)",
                                        "line-height": "1"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Gateway Status")))]
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "padding-left": "20px" } },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "label-txt mt-4" },
                                        [_vm._v(_vm._s(_vm.$t("Offline")))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "innerBox",
                                          staticStyle: {
                                            "justify-content": "normal"
                                          }
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "warning cautionclass"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("Caution")) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c("v-text-field", {
                                            staticClass:
                                              "form-input appendIcon",
                                            staticStyle: {
                                              "max-width": "88px"
                                            },
                                            attrs: {
                                              outlined: "",
                                              dense: "",
                                              "hide-details": "",
                                              solo: "",
                                              suffix: "%",
                                              type: "number",
                                              min: "1",
                                              max: "100",
                                              oninput:
                                                "if(this.value>100){this.value=max;}else if(this.value=='0'){this.value=min;}",
                                              clearable: ""
                                            },
                                            on: {
                                              keypress: _vm.handleKeyUp,
                                              keydown: _vm.clearTabindex
                                            },
                                            model: {
                                              value: _vm.gwOfflineCaution,
                                              callback: function($$v) {
                                                _vm.gwOfflineCaution = $$v
                                              },
                                              expression: "gwOfflineCaution"
                                            }
                                          }),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "error dangerclass"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("Danger")) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c("v-text-field", {
                                            staticClass:
                                              "form-input appendIcon",
                                            staticStyle: {
                                              "max-width": "88px"
                                            },
                                            attrs: {
                                              outlined: "",
                                              dense: "",
                                              "hide-details": "",
                                              solo: "",
                                              suffix: "%",
                                              type: "number",
                                              min: "1",
                                              max: "100",
                                              oninput:
                                                "if(this.value>100){this.value=max;}else if(this.value=='0'){this.value=min;}",
                                              clearable: ""
                                            },
                                            on: {
                                              keypress: _vm.handleKeyUp,
                                              keydown: _vm.clearTabindex
                                            },
                                            model: {
                                              value: _vm.gwOfflineDanger,
                                              callback: function($$v) {
                                                _vm.gwOfflineDanger = $$v
                                              },
                                              expression: "gwOfflineDanger"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { "padding-left": "30px" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "btn-s mt-5",
                                staticStyle: {
                                  "border-radius": "1px !important"
                                },
                                attrs: {
                                  text: "",
                                  disabled:
                                    _vm.btnDisabledAreaSetting ||
                                    _vm.btnDisabledAlertSetting
                                },
                                on: { click: _vm.clickSave }
                              },
                              [_vm._v(_vm._s(_vm.$t("Save")))]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "btn-s mt-5 ml-2",
                                staticStyle: { "border-radius": "1px" },
                                attrs: {
                                  text: "",
                                  disabled:
                                    _vm.btnDisabledAreaSetting ||
                                    _vm.btnDisabledAlertSetting
                                },
                                on: { click: _vm.clickReset }
                              },
                              [_vm._v(_vm._s(_vm.$t("Reset")))]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tab === "tab-2nd"
                ? _c(
                    "v-tab-item",
                    {
                      attrs: {
                        value: "tab-2nd",
                        transition: false,
                        "reverse-transition": false
                      }
                    },
                    [
                      _c("v-card", { attrs: { flat: "" } }, [
                        _c(
                          "div",
                          {
                            class: { hidden: this.SingleUser },
                            staticStyle: { "margin-top": "-77px" }
                          },
                          [
                            _c(
                              "v-row",
                              { staticStyle: { "margin-top": "24px" } },
                              [
                                _c("v-col", { attrs: { cols: "3" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "areaGroup" },
                                    [
                                      _c("h4", { staticClass: "tit" }, [
                                        _vm._v(_vm._s(_vm.$t("Company")))
                                      ]),
                                      _c(
                                        "v-list-item-group",
                                        {
                                          model: {
                                            value: _vm.selectedCompany,
                                            callback: function($$v) {
                                              _vm.selectedCompany = $$v
                                            },
                                            expression: "selectedCompany"
                                          }
                                        },
                                        [
                                          _c("v-virtual-scroll", {
                                            staticClass: "scroll-selector",
                                            attrs: {
                                              items: _vm.companyList,
                                              "item-height": 50,
                                              height: "250"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "v-list-item",
                                                        {
                                                          key: item,
                                                          attrs: {
                                                            value: item
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.clickCompany
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            {
                                                              domProps: {
                                                                textContent: _vm._s(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              508310393
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "area-btn-group" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn",
                                          attrs: {
                                            text: "",
                                            disabled:
                                              _vm.btnController.newCompany ||
                                              _vm.btnDisabledAlertSetting
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.clickAreaBtn(
                                                "new",
                                                "company"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Add")))]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn ml-1",
                                          attrs: {
                                            text: "",
                                            disabled:
                                              _vm.btnController.company ||
                                              _vm.btnDisabledAlertSetting
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.clickAreaBtn(
                                                "edit",
                                                "company"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Edit")))]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn ml-1",
                                          attrs: {
                                            text: "",
                                            disabled:
                                              _vm.btnController.company ||
                                              _vm.btnDisabledAlertSetting
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteArea("company")
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Delete")))]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _c("v-col", { attrs: { cols: "3" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "areaGroup" },
                                    [
                                      _c("h4", { staticClass: "tit" }, [
                                        _vm._v(_vm._s(_vm.$t("Country")))
                                      ]),
                                      _c(
                                        "v-list-item-group",
                                        {
                                          model: {
                                            value: _vm.selectedCountry,
                                            callback: function($$v) {
                                              _vm.selectedCountry = $$v
                                            },
                                            expression: "selectedCountry"
                                          }
                                        },
                                        [
                                          _c("v-virtual-scroll", {
                                            staticClass: "scroll-selector",
                                            attrs: {
                                              items: _vm.countryList,
                                              "item-height": 50,
                                              height: "250"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "v-list-item",
                                                        {
                                                          key: item,
                                                          attrs: {
                                                            value: item
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.clickCountry
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            {
                                                              domProps: {
                                                                textContent: _vm._s(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2296999286
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "area-btn-group" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn",
                                          attrs: {
                                            text: "",
                                            disabled:
                                              _vm.btnController.company ||
                                              _vm.btnDisabledAlertSetting
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.clickAreaBtn(
                                                "new",
                                                "country"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Add")))]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn ml-1",
                                          attrs: {
                                            text: "",
                                            disabled:
                                              _vm.btnController.country ||
                                              _vm.btnDisabledAlertSetting
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.clickAreaBtn(
                                                "edit",
                                                "country"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Edit")))]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn ml-1",
                                          attrs: {
                                            text: "",
                                            disabled:
                                              _vm.btnController.country ||
                                              _vm.btnDisabledAlertSetting
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteArea("country")
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Delete")))]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _c("v-col", { attrs: { cols: "3" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "areaGroup" },
                                    [
                                      _c("h4", { staticClass: "tit" }, [
                                        _vm._v(_vm._s(_vm.$t("Region")))
                                      ]),
                                      _c(
                                        "v-list-item-group",
                                        {
                                          model: {
                                            value: _vm.selectedRegion,
                                            callback: function($$v) {
                                              _vm.selectedRegion = $$v
                                            },
                                            expression: "selectedRegion"
                                          }
                                        },
                                        [
                                          _c("v-virtual-scroll", {
                                            staticClass: "scroll-selector",
                                            attrs: {
                                              items: _vm.regionList,
                                              "item-height": 50,
                                              height: "250"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "v-list-item",
                                                        {
                                                          key: item,
                                                          attrs: {
                                                            value: item
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.clickRegion
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            {
                                                              domProps: {
                                                                textContent: _vm._s(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2375860678
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "area-btn-group" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn",
                                          attrs: {
                                            text: "",
                                            disabled:
                                              _vm.btnController.country ||
                                              _vm.btnDisabledAlertSetting
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.clickAreaBtn(
                                                "new",
                                                "region"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Add")))]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn ml-1",
                                          attrs: {
                                            text: "",
                                            disabled:
                                              _vm.btnController.region ||
                                              _vm.btnDisabledAlertSetting
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.clickAreaBtn(
                                                "edit",
                                                "region"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Edit")))]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn ml-1",
                                          attrs: {
                                            text: "",
                                            disabled:
                                              _vm.btnController.region ||
                                              _vm.btnDisabledAlertSetting
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteArea("region")
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Delete")))]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _c("v-col", { attrs: { cols: "3" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "areaGroup" },
                                    [
                                      _c("h4", { staticClass: "tit" }, [
                                        _vm._v(_vm._s(_vm.$t("City")))
                                      ]),
                                      _c(
                                        "v-list-item-group",
                                        {
                                          model: {
                                            value: _vm.selectedCity,
                                            callback: function($$v) {
                                              _vm.selectedCity = $$v
                                            },
                                            expression: "selectedCity"
                                          }
                                        },
                                        [
                                          _c("v-virtual-scroll", {
                                            staticClass: "scroll-selector",
                                            attrs: {
                                              items: _vm.cityList,
                                              "item-height": 50,
                                              height: "250"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "v-list-item",
                                                        {
                                                          key: item,
                                                          attrs: { value: item }
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            {
                                                              domProps: {
                                                                textContent: _vm._s(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              3022906421
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "area-btn-group" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn",
                                          attrs: {
                                            text: "",
                                            disabled:
                                              _vm.btnController.region ||
                                              _vm.btnDisabledAlertSetting
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.clickAreaBtn(
                                                "new",
                                                "city"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Add")))]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn ml-1",
                                          attrs: {
                                            text: "",
                                            disabled:
                                              _vm.btnController.city ||
                                              _vm.btnDisabledAlertSetting
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.clickAreaBtn(
                                                "edit",
                                                "city"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Edit")))]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn ml-1",
                                          attrs: {
                                            text: "",
                                            disabled:
                                              _vm.btnController.city ||
                                              _vm.btnDisabledAlertSetting
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteArea("city")
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Delete")))]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _c("areaSettingModal", {
                                  attrs: {
                                    dialog: _vm.dialogArea,
                                    action: _vm.actionAreaModal,
                                    category: _vm.categoryAreaModal,
                                    item: _vm.valueAreaModal
                                  },
                                  on: {
                                    saveArea: _vm.saveArea,
                                    closeAreaModal: _vm.closeAreaSettingModal
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tab === "tab-3rd"
                ? _c(
                    "v-tab-item",
                    {
                      attrs: {
                        value: "tab-3rd",
                        transition: false,
                        "reverse-transition": false
                      }
                    },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [_c("report-config")],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tab === "tab-4th"
                ? _c(
                    "v-tab-item",
                    {
                      attrs: {
                        value: "tab-4th",
                        transition: false,
                        "reverse-transition": false
                      }
                    },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [_c("default-set")],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }