<template>
  <div>
    <v-row>
      <v-col>
        <h3
          class="tit-group-bg mt-10"
          style="color: rgba(0, 0, 0, 0.6); line-height: 1"
        >
          {{ $t("Dashboard Language") }}
        </h3>
      </v-col>
    </v-row>

    <div>
      <p class="label-txt">
        {{ $t("Language List") }}
      </p>
      <v-row style="padding: 0">
        <v-col style="height 20px;max-width:280px;" class="">
          <div
            class="custom-scrollBox"
            style="overflow: scroll; height: 250px; padding-right: 0px"
          >
            <ul style="padding-left: 0px" class="label-txt">
              <li
                v-for="(language, index) in languagelist"
                style="cursor: pointer"
                :class="{ active: activeIndex === index }"
                :key="language.id"
                @click="setActive(language, index)"
              >
                {{ language.languageName }}
              </li>
            </ul>
          </div>
          <div class="mt-5">
            <v-btn class="btn-s" :disabled='btnDisabledDashboardSettings' text @click="Addnewlanguage">
              {{ $t("Add") }}
            </v-btn>
          </div>
        </v-col>
        <v-col style="max-width: 10px"> </v-col>
        <v-col class="languagesetting">
          <p class="label-txt">
            {{ $t("Language Setting") }}
          </p>
          <br />
          <v-row style="padding-left: 13px">
            <div>
              <p class="label-txt">
                {{ $t("Language") }}
              </p>
              <div
                class=""
                style="justify-content: normal; display: inline-flex"
              >
                <v-row>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    solo
                    v-model="language_Name"
                    :placeholder="`${$t('title')}`"
                    :disabled="defaultLanguageenable && !addbutton || currentlanguagenamevalue"
                    class="form-input appendIcon"
                    type="text"
                    style="max-width: 100px; margin-left: 10px"
                    clearable
                    @keydown="clearTabindex"
                  ></v-text-field>
                </v-row>
              </div>
            </div>

            <div style="margin-left: 30px" v-if="!addbutton">
              <p class="label-txt">
                {{ $t("Current Language File") }}
              </p>
              <div
                class=""
                style="justify-content: normal; display: inline-flex"
              >
                <v-row>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    solo
                    v-model="langfileName"
                    class="form-input appendIcon"
                    type="text"
                    :disabled="defaultLanguageenable && !addbutton || currentlanguagenamevalue"
                    style="max-width: 250px; margin-left: 10px"
                    clearable
                    @keydown="clearTabindex"
                  ></v-text-field>
                  <span style="margin-left: 10px;" v-bind:class="{ disabledSpan: btnDisabledDashboardSettings}"   @click="downloadfile()" >
                    <img
                      width="24"
                      height="24"
                      v-bind:class="{ disabled: btnDisabledDashboardSettings, 'downloadicon': !btnDisabledDashboardSettings }"
                      src="@/assets/img/download_icon.png"
                    />
                  </span>
                  <a ref="link" :style="{ display: 'none' }" />
                </v-row>
              </div>
            </div>
            <div style="margin-left: 30px">
              <p class="label-txt">
                {{ $t("Language File Import") }}
              </p>
              <div class="fileinput">
                <v-row>
                  <v-col style="cursor: pointer;">
                    <v-file-input
                      outlined
                      dense
                      hide-details
                      prepend-icon
                      solo
                      flat
                      :disabled='btnDisabledDashboardSettings'
                      v-model="chosenFile"
                      style="width: 200px;cursor:pointer"
                      ref="inputFile"
                      :placeholder="`${$t('File Upload')}`"
                      @change="onChange"
                      class="form-file"
                    >
                      <template v-slot:append>
                        <img
                        style="cursor:pointer"
                          width="24"
                          height="24"
                          src="@/assets/img/ico-upload-file.gif"
                        />
                      </template>
                    </v-file-input>
                  </v-col>
                  <div class="mt-3" v-if="addbutton">
              <v-btn class="btn-m"  style="background: #001e38;color: #fff;cursor:pointer" text @click="downloadguidefile">
                {{ $t("Download Guide File") }}
              </v-btn>
            </div>
                  <a ref="link1" :style="{ display: 'none' }" />
                </v-row>
              </div>
            </div>
          </v-row>

            <v-row justify="start" style="margin-left: 0px;">
            <div class="mt-5">
              <v-btn class="btn-s" text @click="toSavelanguageContent" :disabled='chosenFile===null||chosenFile===undefined|| btnDisabledDashboardSettings'>
                {{ $t("Save") }}
              </v-btn>
            </div>
            <div class="mt-5 ml-2" v-if="!addbutton">
              <v-btn class="btn-s" :disabled='btnDisabledDashboardSettings' text @click="toRestlanguageContent">
                {{ $t("Reset") }}
              </v-btn>
            </div>
            <div class="mt-5 ml-2">
              <v-btn v-if="!defaultLanguageenable && !addbutton" class="btn-s" text @click="Todeletelanguagelist">
                {{ $t("Delete") }}
              </v-btn>
            </div>
            </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import EventBus from '@/plugins/eventBus'
// import commons from '@/plugins/commons'
import { exportFiles } from '@/plugins/exporter'
import codes from '@/plugins/codes'
import XLSX from '../../../node_modules/xlsx/dist/xlsx.full.min.js'

export default {
  components: {},
  data () {
    return {
      languagelist: [],
      activeIndex: undefined,
      defaultLanguageenable: false,
      language_Name: '',
      langfileName: '',
      lang_id: '',
      Jsonfile: '',
      Jsonfile1: undefined,
      chosenFile: null,
      addbutton: false,
      currentlanguagename: this.$store.getters['dataStore/GET_CURRENT_LANGUAGE'],
      currentlanguagenamevalue: false,
      requestConfig: {
        url: '',
        method: '',
        params: {
          sort: null
        }
      },
      // button disabled
      btnDisabledDashboardSettings: null
    }
  },
  mounted () {
    this.togetlanguagelist()
    this.currentlanguagename = this.$store.getters['dataStore/GET_CURRENT_LANGUAGE']
    this.$store.dispatch('auth/getDisabledBtn', '9100').then((flag) => {
      this.btnDisabledDashboardSettings = flag
    })
  },
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    // To get initial all language list
    togetlanguagelist () {
      const config = { params: {} }
      this.$utils
        .callAxios(
          codes.requests.getLanguageList.method,
          codes.requests.getLanguageList.url,
          config
        )
        .then((res) => {
          if (res.data) {
            console.log(res.data)
            this.languagelist = res.data.languageList
            const userSubscriptionKey = this.languagelist[0].languageId
            const indexOfObject = this.languagelist.findIndex(
              (o) => o.languageId === userSubscriptionKey
            )
            this.setActive(this.languagelist[0], indexOfObject)
          }
        })
        .catch((error) => {
          console.warn(`Could not find any Language List : ${error}`)
        })
    },

    // Active class
    setActive (subscription, index) {
      this.currentlanguagename = this.$store.getters['dataStore/GET_CURRENT_LANGUAGE']
      this.activeIndex = index
      this.languagelist[index].languageId = subscription.languageId
      if (this.addbutton === false) {
        this.togetlanguagecontent(subscription.languageId)
      }
      // this.$i18n.locale = 'fr'
      // this.currentLanguage = codeName
      // this.$store.dispatch('dataStore/UPDATE_CURRENT_LANGUAGE', 'fr')
      // this.$forceUpdate()
    },

    // To get language content
    togetlanguagecontent (id) {
      this.currentlanguagenamevalue = false

      console.log(id)
      this.lang_id = id
      const config = {
        params: {
          languageId: id,
          export: null
        }
      }
      this.$utils
        .callAxios(
          codes.requests.getLanguageContent.method,
          codes.requests.getLanguageContent.url,
          config
        )
        .then((res) => {
          if (res.data) {
            console.log(res.data)
            console.log(res.config)
            this.saveRequestConfig(res.config)
            this.defaultLanguageenable = res.data.defaultLanguage
            this.language_Name = res.data.languageName
            this.langfileName = res.data.fileName
            console.log(this.currentlanguagename)
            console.log(this.language_Name)
            if (this.currentlanguagename === this.language_Name) {
              this.currentlanguagenamevalue = true
            }
          }
        })
        .catch((error) => {
          console.warn(`Could not find any Language content : ${error}`)
        })
    },

    // Setting config
    saveRequestConfig: function (config) {
      const obj = {
        languageId: this.lang_id,
        export: true
      }
      const requestConfig = {
        url: config.url,
        method: config.method,
        params: obj
      }
      this.requestConfig = requestConfig
    },

    // to download language file
    downloadfile () {
      exportFiles(this.requestConfig, this.$refs.link, 'Language.xlsx')
    },

    // To upload or import the excel language file
    onChange (e) {
      console.log(this.chosenFile)
      if (this.chosenFile !== undefined) {
        var oFile = e
        var Filename = oFile.name
        localStorage.setItem('sheetname', Filename)
        var reader = new FileReader()
        reader.onload = function (e) {
          var data = e.target.result
          var workbook = XLSX.read(data, {
            type: 'binary'
          })
          workbook.SheetNames.forEach(function (sheetName) {
          // Here is your object
            var XLrowobject = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[sheetName]
            )
            var jsonobject = JSON.stringify(XLrowobject)
            localStorage.setItem('Objectfile', jsonobject)
          })
        }
        reader.readAsBinaryString(e)
      }
    },

    // To save language content
    toSavelanguageContent () {
      console.log(this.chosenFile)
      console.log(this.langfileName)
      if (this.language_Name === null || this.langfileName === null) {
        EventBus.$emit('messageAlert', this.$t('Enter the Values to Proceed'))
        return
      }
      if (this.chosenFile !== null) {
        const data = JSON.parse(localStorage.getItem('Objectfile'))
        const sheetname = localStorage.getItem('sheetname')
        var objectvalue = []
        var obj
        for (let i = 0; i < data.length; i++) {
          const obj1 = {
            [data[i].KEY]: data[i]['LANGUAGE VALUE']
          }
          obj = obj1
          objectvalue.push(obj)
          var merged = Object.assign.apply(Object, objectvalue)
        }
        const config = { params: {} }
        var body
        if (this.addbutton === true) {
          body = {
            languageName: this.language_Name,
            defaultLanguage: false,
            fileName: sheetname,
            content: JSON.stringify(merged)
          }
        } else {
          body = {
            languageId: this.lang_id,
            languageName: this.language_Name,
            defaultLanguage: true,
            fileName: sheetname,
            content: JSON.stringify(merged)
          }
        }

        this.$utils
          .callAxiosWithBody(
            codes.requests.toUpdateLanguageContent.method,
            codes.requests.toUpdateLanguageContent.url,
            body,
            config
          )
          .then((res) => {
            console.log(res.data)
            EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
            localStorage.setItem('Objectfile', '')
            localStorage.setItem('sheetname', '')
            this.chosenFile = null
            this.togetlanguagelist()
            this.addbutton = false
          })
          .catch((error) => {
            console.debug(`Failed to Update Language Content. error: ${error}`)
          })
      }
    },

    // To Reset Language content
    toRestlanguageContent () {
      const config = {
        params: {
          reset: true
        }
      }
      const body = {
        languageId: this.lang_id
      }
      this.$utils
        .callAxiosWithBody(
          codes.requests.toUpdateLanguageContent.method,
          codes.requests.toUpdateLanguageContent.url,
          body,
          config
        )
        .then((res) => {
          console.log(res.data)
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
        })
        .catch((error) => {
          console.debug(`Failed to Reset. error: ${error}`)
        })
    },

    // To add New Language
    Addnewlanguage () {
      this.addbutton = true
      this.currentlanguagenamevalue = false

      console.log(this.languagelist)
      const obj = {
        defaultLanguage: false,
        languageId: this.languagelist.length + 1,
        languageName: 'New Language'
      }
      this.languagelist.push(obj)
      console.log(this.languagelist.length)
      this.language_Name = ''
      this.langfileName = ''
      this.setActive(this.languagelist[this.languagelist.length - 1], this.languagelist.length - 1)
    },
    downloadguidefile () {
      const dataguide = this.requestConfig
      dataguide.params.guide = true
      dataguide.params.languageId = 1
      exportFiles(dataguide, this.$refs.link1, 'LanguageGuide.xlsx')
    },

    // To delete language list
    // todeleteLanguageList
    Todeletelanguagelist () {
      const config = {
        params: {
          languageId: this.lang_id
        }
      }
      this.$utils
        .callAxios(
          codes.requests.todeleteLanguageList.method,
          codes.requests.todeleteLanguageList.url,
          config
        )
        .then((res) => {
          if (res.data) {
            this.togetlanguagelist()
            EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          }
        })
        .catch((error) => {
          console.warn(`Could not delete Language list : ${error}`)
        })
    }
  }
}
</script>
<style scoped>
li {
  padding: 10px;
  text-align: center;
}
.languagesetting {
  height: 250px;
  background: rgb(255, 255, 255) !important;
  border: 1px solid rgb(245, 245, 245);
  box-shadow: rgb(220 212 212) 0px 1px 5px;
  padding: 30px;
  border-radius: 5px;
  margin: 10px;
  margin-left: -10px;
}
.active {
  background: #001e38;
  color: white;
}
.fileinput {
  justify-content: normal;
  display: inline-flex;
  margin-top: -12px;
}
.downloadicon {
  background: rgb(0, 30, 56);
  height: 40px;
  width: 50px;
  padding: 9px;
  border-radius: 6px;
  cursor:pointer;
}
.disabled{
  background: #c9cace !important;
  height: 40px;
  width: 50px;
  padding: 9px;
  border-radius: 6px;
  pointer-events: none !important;
}
.disabledSpan{
  pointer-events: none !important;

}
</style>
