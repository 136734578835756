<template>
  <div>
    <v-row>
      <v-col>
        <h3
          class="tit-group-bg mt-10"
          style="color: rgba(0, 0, 0, 0.6); line-height: 1"
        >
          {{ $t("Family Link Menu Settings") }}
        </h3>
      </v-col>
    </v-row>
    <v-row class="label-txt" style="width: 100%; margin: 0; padding: 0px">
      <table class="tbl-custom-type01 max-100" cellspacing="0" cellpadding="0">
        <colgroup>
          <col style="width: 20%" />
          <col style="width: 30%" />
          <col style="width: 45%" />
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t("Module Name") }}</th>
            <th>{{ $t("Module Path") }}</th>
            <th>{{ $t("On/Off") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr style="vertical-align: baseline">
            <td>
              {{ $t("LD") }}
            </td>
            <td>
              <v-text-field
                outlined
                hide-details
                dense
                v-model="layOutDesigner.path"
              ></v-text-field>
            </td>
            <td>
              <v-switch
                inset
                hide-details
                solo
                class="form-switch"
                v-model="layOutDesigner.used"
              ></v-switch>
            </td>
          </tr>
          <tr style="vertical-align: baseline">
            <td>
              {{ $t("PDA") }}
            </td>
            <td>
              <v-text-field
                outlined
                hide-details
                dense
                v-model="webPDA.path"
              ></v-text-field>
            </td>
            <td>
              <v-switch
                inset
                hide-details
                solo
                class="form-switch"
                v-model="webPDA.used"
              ></v-switch>
            </td>
          </tr>
          <h5 class="APIDocClass" style="">
            <th>{{ $t("API DOCS") }}</th>
          </h5>
          <tr style="vertical-align: baseline">
            <td>
              {{ $t("DasboardWeb") }}
            </td>
            <td>
              <v-text-field
                outlined
                hide-details
                dense
                v-model="dashboardService.path"
              ></v-text-field>
            </td>
            <td>
              <v-switch
                inset
                hide-details
                solo
                class="form-switch"
                v-model="dashboardService.used"
              ></v-switch>
            </td>
          </tr>
          <tr style="vertical-align: baseline">
            <td>
              {{ $t("Portal") }}
            </td>
            <td>
              <v-text-field
                outlined
                hide-details
                dense
                v-model="portal.path"
              ></v-text-field>
            </td>
            <td>
              <v-switch
                inset
                hide-details
                solo
                class="form-switch"
                v-model="portal.used"
              ></v-switch>
            </td>
          </tr>
          <!-- <tr style="vertical-align: baseline">
            <td>
              {{ $t("OTA") }}
            </td>
            <td>
              <v-text-field
                outlined
                hide-details
                dense
                v-model="otaManager.path"
              ></v-text-field>
            </td>
            <td>
              <v-switch
                inset
                hide-details
                solo
                class="form-switch"
                v-model="otaManager.used"
              ></v-switch>
            </td>
          </tr> -->
          <!-- <tr style="vertical-align: baseline">
            <td>
              {{ $t("LBS") }}
            </td>
            <td>
              <v-text-field
                outlined
                hide-details
                dense
                v-model="lbsManager.path"
              >
              </v-text-field>
            </td>
            <td>
              <v-switch
                inset
                hide-details
                solo
                class="form-switch"
                v-model="lbsManager.used"
              ></v-switch>
            </td>
          </tr> -->
        </tbody>
      </table>
    </v-row>
    <v-row>
      <div class="mt-5" style="margin-left: 21px">
        <v-btn
          :disabled="btnDisabledDashboardSettings"
          @click="saveAPIPath()"
          class="btn-s ml-2"
          text
          >{{ $t("Save") }}</v-btn
        >
      </div>
    </v-row>
  </div>
</template>
<script>
// import commons from '@/plugins/commons'
// import configs from '@/plugins/configs'
import EventBus from '@/plugins/eventBus.js'
const requests = {
  getApiPathApi: {
    method: 'get',
    url: '/api/common/config/APIPath'
  },
  postApiPathApi: {
    method: 'POST',
    url: '/api/common/config/APIPath'
  }
}
export default {
  data () {
    return {
      dashboardService: { path: '/dashboard', used: true },
      layOutDesigner: { path: '/LD', used: true },
      //   lbsManager: { path: '/lbs', used: true },
      //   otaManager: { path: '/dashboardWeb/common/otamanager', used: true },
      portal: { path: '/', used: true },
      webPDA: { path: '/PDA', used: true },
      btnDisabledDashboardSettings: false
    }
  },
  methods: {
    // inital load data
    getApiPath () {
      const params = {}
      const config = { params: params }
      this.$utils
        .callAxios(
          requests.getApiPathApi.method,
          requests.getApiPathApi.url,
          config
        )
        .then((res) => {
          console.log(res)
          this.dashboardService.path = res.data.dashboardService.path
          this.dashboardService.used = res.data.dashboardService.used

          this.layOutDesigner.path = res.data.layOutDesigner.path
          this.layOutDesigner.used = res.data.layOutDesigner.used

          //   this.lbsManager.path = res.data.lbsManager.path
          //   this.lbsManager.used = res.data.lbsManager.used

          this.portal.path = res.data.portal.path
          this.portal.used = res.data.portal.used

          this.webPDA.path = res.data.webPDA.path
          this.webPDA.used = res.data.webPDA.used

        //   this.otaManager.path = res.data.otaManager.path
        //   this.otaManager.used = res.data.otaManager.used
        })
    },

    // ToSaveAPI Path
    saveAPIPath () {
      const body = {
        dashboardService: {
          path: this.dashboardService.path,
          used: this.dashboardService.used
        },
        layOutDesigner: {
          path: this.layOutDesigner.path,
          used: this.layOutDesigner.used
        },
        // lbsManager: {
        //   path: this.lbsManager.path,
        //   used: this.lbsManager.used
        // },
        // otaManager: {
        //   path: this.otaManager.path,
        //   used: this.otaManager.used
        // },
        portal: {
          path: this.portal.path,
          used: this.portal.used
        },
        webPDA: {
          path: this.webPDA.path,
          used: this.webPDA.used
        }
      }

      console.log(body)
      const params = {}
      const config = { params: params }
      this.$utils
        .callAxiosWithBody(
          requests.postApiPathApi.method,
          requests.postApiPathApi.url,
          body,
          config
        )
        .then((res) => {
          console.log(res.data)
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          this.getApiPath()
        })
        .catch(error => {
          console.debug(`failed to save. erorr: ${error}`)
        })
    }
  },
  mounted () {
    this.getApiPath()
    this.$store.dispatch('auth/getDisabledBtn', '9100').then((flag) => {
      this.btnDisabledDashboardSettings = flag
    })
  }
}
</script>

<style scoped>
table {
  border: none;
}
.tbl-custom-type01 tbody td {
  height: 50px;
  padding-left: 30px;
  padding-right: 5px;
  color: #4d4f5c;
  border-bottom: none !important;
  font-size: var(--font14);
}
.v-text-field.v-text-field--enclosed {
  margin: 0;
  padding: 0;
  margin-top: 20px !important;
  width: 70%;
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
}
.APIDocClass {
  color: rgba(0, 0, 0, 0.6);
  line-height: 1;
  padding: 10px;
  margin-top: 10px;
  font-size: 12px;
  margin-left: 10px;
  text-decoration: underline;
}
.tbl-custom-type01 thead th {
  position: relative;
  height: 50px;
  padding-left: 20px !important;
  padding-right: 5px;
  border-bottom: 0;
  background: #fafafa;
  color: #4d4f5c;
  font-weight: 700;
  font-size: var(--font14);
  overflow: hidden;
  text-align: left;
}
.tbl-custom-type01 tbody td[data-v-6dcf4b92] {
  height: 50px;
  padding-left: 20px !important;
  padding-right: 5px;
  color: #4d4f5c;
  border-bottom: none !important;
  font-size: var(--font14);
}
</style>
