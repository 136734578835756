var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-top": "20px" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c(
              "div",
              [
                _c("div", [
                  _c(
                    "h3",
                    {
                      staticClass: "tit-group-bg",
                      staticStyle: {
                        color: "rgba(0, 0, 0, 0.6)",
                        "line-height": "1"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("Import Existing Setting Values")) +
                          " "
                      )
                    ]
                  )
                ]),
                _c("br"),
                _c(
                  "v-col",
                  { staticClass: "exportsetting" },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "label-txt",
                            staticStyle: { "margin-top": "-30px" },
                            attrs: { align: "start" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "row",
                                staticStyle: { margin: "0" }
                              },
                              [
                                _c("v-checkbox", {
                                  attrs: { "hide-details": "" },
                                  model: {
                                    value: _vm.templateimport,
                                    callback: function($$v) {
                                      _vm.templateimport = $$v
                                    },
                                    expression: "templateimport"
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "label-txt",
                                    staticStyle: { "margin-top": "23px" }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Template")))]
                                )
                              ],
                              1
                            ),
                            _vm.templatemapping === "true"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "row",
                                    staticStyle: { margin: "0" }
                                  },
                                  [
                                    _c("v-checkbox", {
                                      attrs: { "hide-details": "" },
                                      model: {
                                        value: _vm.templatemappingimport,
                                        callback: function($$v) {
                                          _vm.templatemappingimport = $$v
                                        },
                                        expression: "templatemappingimport"
                                      }
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "label-txt",
                                        staticStyle: { "margin-top": "23px" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("Template Group & Mapping")
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "row",
                                staticStyle: { margin: "0" }
                              },
                              [
                                _c("v-checkbox", {
                                  attrs: { "hide-details": "" },
                                  model: {
                                    value: _vm.ledpromotionimport,
                                    callback: function($$v) {
                                      _vm.ledpromotionimport = $$v
                                    },
                                    expression: "ledpromotionimport"
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "label-txt",
                                    staticStyle: { "margin-top": "23px" }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("LED Promotion")))]
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "label-txt",
                            staticStyle: { "margin-top": "-30px" },
                            attrs: { align: "start" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "row",
                                staticStyle: { margin: "0" }
                              },
                              [
                                _c("v-checkbox", {
                                  attrs: { "hide-details": "" },
                                  model: {
                                    value: _vm.userlevelimport,
                                    callback: function($$v) {
                                      _vm.userlevelimport = $$v
                                    },
                                    expression: "userlevelimport"
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "label-txt",
                                    staticStyle: { "margin-top": "23px" }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("User Levels")))]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "row",
                                staticStyle: { margin: "0" }
                              },
                              [
                                _c("v-checkbox", {
                                  attrs: { "hide-details": "" },
                                  model: {
                                    value: _vm.languageimport,
                                    callback: function($$v) {
                                      _vm.languageimport = $$v
                                    },
                                    expression: "languageimport"
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "label-txt",
                                    staticStyle: { "margin-top": "23px" }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Language")))]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "row",
                                staticStyle: { margin: "0" }
                              },
                              [
                                _c("v-checkbox", {
                                  attrs: { "hide-details": "" },
                                  model: {
                                    value: _vm.systemconfigimport,
                                    callback: function($$v) {
                                      _vm.systemconfigimport = $$v
                                    },
                                    expression: "systemconfigimport"
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "label-txt",
                                    staticStyle: { "margin-top": "23px" }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("System Config")))]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("br"),
            _c(
              "div",
              { staticClass: "row", staticStyle: { margin: "0px" } },
              [
                _c("v-file-input", {
                  staticClass: "form-file",
                  staticStyle: { "max-width": "230px" },
                  attrs: {
                    outlined: "",
                    dense: "",
                    accept: ".zip",
                    "hide-details": "",
                    "prepend-icon": "",
                    solo: "",
                    flat: "",
                    placeholder: "" + _vm.$t("File Upload")
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "append",
                      fn: function() {
                        return [
                          _c("img", {
                            attrs: {
                              width: "24",
                              height: "24",
                              src: require("@/assets/img/ico-upload-file.gif")
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.zipFile,
                    callback: function($$v) {
                      _vm.zipFile = $$v
                    },
                    expression: "zipFile"
                  }
                }),
                _c(
                  "span",
                  { staticClass: "label-txt", staticStyle: { margin: "18px" } },
                  [_vm._v("* . " + _vm._s(_vm.$t("zip")))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mt-5" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "btn-s",
                    attrs: {
                      disabled: _vm.btnDisabledDashboardSettings,
                      text: ""
                    },
                    on: { click: _vm.uploadZip }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("Import")) + " ")]
                )
              ],
              1
            )
          ]),
          _c("v-col", [
            _c(
              "div",
              [
                _c("div", [
                  _c(
                    "h3",
                    {
                      staticClass: "tit-group-bg",
                      staticStyle: {
                        color: "rgba(0, 0, 0, 0.6)",
                        "line-height": "1"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("Export Existing Setting Values")) +
                          " "
                      )
                    ]
                  )
                ]),
                _c("br"),
                _c(
                  "v-col",
                  { staticClass: "exportsetting" },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "label-txt",
                            staticStyle: { "margin-top": "-30px" },
                            attrs: { align: "start" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "row",
                                staticStyle: { margin: "0" }
                              },
                              [
                                _c("v-checkbox", {
                                  attrs: { "hide-details": "" },
                                  model: {
                                    value: _vm.templateexport,
                                    callback: function($$v) {
                                      _vm.templateexport = $$v
                                    },
                                    expression: "templateexport"
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "label-txt",
                                    staticStyle: { "margin-top": "23px" }
                                  },
                                  [_vm._v("Template")]
                                )
                              ],
                              1
                            ),
                            _vm.templatemapping === "true"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "row",
                                    staticStyle: { margin: "0" }
                                  },
                                  [
                                    _c("v-checkbox", {
                                      attrs: { "hide-details": "" },
                                      model: {
                                        value: _vm.templatemappingexport,
                                        callback: function($$v) {
                                          _vm.templatemappingexport = $$v
                                        },
                                        expression: "templatemappingexport"
                                      }
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "label-txt",
                                        staticStyle: { "margin-top": "23px" }
                                      },
                                      [_vm._v("Template Group & Mapping")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "row",
                                staticStyle: { margin: "0" }
                              },
                              [
                                _c("v-checkbox", {
                                  attrs: { "hide-details": "" },
                                  model: {
                                    value: _vm.ledpromotionexport,
                                    callback: function($$v) {
                                      _vm.ledpromotionexport = $$v
                                    },
                                    expression: "ledpromotionexport"
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "label-txt",
                                    staticStyle: { "margin-top": "23px" }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("LED Promotion")))]
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "label-txt",
                            staticStyle: { "margin-top": "-30px" },
                            attrs: { align: "start" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "row",
                                staticStyle: { margin: "0" }
                              },
                              [
                                _c("v-checkbox", {
                                  attrs: { "hide-details": "" },
                                  model: {
                                    value: _vm.userlevelexport,
                                    callback: function($$v) {
                                      _vm.userlevelexport = $$v
                                    },
                                    expression: "userlevelexport"
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "label-txt",
                                    staticStyle: { "margin-top": "23px" }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("User Levels")))]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "row",
                                staticStyle: { margin: "0" }
                              },
                              [
                                _c("v-checkbox", {
                                  attrs: { "hide-details": "" },
                                  model: {
                                    value: _vm.languageexport,
                                    callback: function($$v) {
                                      _vm.languageexport = $$v
                                    },
                                    expression: "languageexport"
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "label-txt",
                                    staticStyle: { "margin-top": "23px" }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Language")))]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "row",
                                staticStyle: { margin: "0" }
                              },
                              [
                                _c("v-checkbox", {
                                  attrs: { "hide-details": "" },
                                  model: {
                                    value: _vm.systemconfigexport,
                                    callback: function($$v) {
                                      _vm.systemconfigexport = $$v
                                    },
                                    expression: "systemconfigexport"
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "label-txt",
                                    staticStyle: { "margin-top": "23px" }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("System Config")))]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-5" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-s",
                        attrs: {
                          disabled: _vm.btnDisabledDashboardSettings,
                          text: ""
                        },
                        on: { click: _vm.gettoGetsettingValues }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Export")) + " ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("a", { ref: "link1", style: { display: "none" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }