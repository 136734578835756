<template>
    <div>
        <v-row>
      <v-col>
        <h3
          class="tit-group-bg mt-10"
          style="color: rgba(0, 0, 0, 0.6); line-height: 1"
        >
          {{ $t("REPORT SETTINGS") }}
        </h3>
      </v-col>
    </v-row>
    <div class="label-txt  max-100" style="width: 100%; margin: 0; padding: 0px">
    <v-row >
      <v-col class="col-3" style="margin-top: 20px;margin-left: 20px;">
         <span >{{ $t("Product Report") }}</span>
      </v-col>
      <v-col style="margin-left: -10px;">
        <v-switch
          inset
          hide-details
          solo
          class="form-switch"
          v-model="reportEnabled"
          @change="reportSwitch"
          @keypress="handleKeyUpspl"

        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
    <v-col class="col-3">
        <p class="label-txt" style="margin-top: 30px;margin-left: 20px;">{{ $t("Product Report Schedule") }}</p>
        <br />
</v-col>
<v-col style="margin-left: -10px;">
  <div class="" style="justify-content: normal; display: inline-flex">
        <v-row>
          <v-text-field
            v-model="reportSchedule"
            :disabled="btnDisabledReportSchedule"
            class="form-input"
            style="max-width: 88px; margin-left: 10px"
            type="number"
            outlined
            dense
            hide-details
            @keypress="handleKeyUpspl"
          ></v-text-field>
        <span><p
                class="label-txt"
                style="
                font-weight: bold;
                margin-left: 12px;
                margin-top: 30px;">{{ $t("minutes") }}</p></span>
        </v-row>
      </div>
</v-col>
</v-row>
<v-row>
    <v-col class="col-3">
        <p class="label-txt" style="margin-left: 20px;margin-top: 30px;">{{ $t("Product Report Path") }}</p>
        <br />
</v-col>
<v-col>
  <div class="" style="justify-content: normal; display: inline-flex">
        <v-row>
          <v-text-field
            v-model="reportPath"
            :disabled="btnDisabledReportPath"
            :placeholder="`${$t('Set product file upload path')}`"
            class="form-input"
            style="width:300px"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-row>
      </div>
</v-col></v-row>
    <div class="mt-5" style="margin-left: 10px;">
            <v-btn :disabled="btnDisabledReportSave" class="btn-s ml-2" @click="saveReportSetting()" text>
              {{ $t("Save") }}
            </v-btn>
          </div>
  </div>
    </div>
</template>
<script>
import EventBus from '@/plugins/eventBus.js'
const requests = {
  getReportApi: {
    method: 'get',
    url: '/api/common/property'
  },
  putReportApi: {
    method: 'put',
    url: '/api/common/property'
  }

}
export default {
  data () {
    return {
      reportEnabled: false,
      reportSchedule: '',
      reportPath: '',
      btnDisabledReportSave: true,
      btnDisabledReportSchedule: true,
      btnDisabledReportPath: true,
      getInitialData: '',
      showErrorMessage: false
      // rules: {
      //   min: value => value >= 30 || 'Minutes should be  greater than 30',
      //   max: value => value <= 180 || 'Minutes should be less than 180'

      // }

    }
  },
  methods: {
    handleKeyUpspl (e) {
      if (e.keyCode === 37 || e.keyCode === 59 || e.keyCode === 47 || e.keyCode === 44 || e.keyCode === 92 || e.keyCode === 46 || e.keyCode === 101 || e.keyCode === 43) {
        e.preventDefault()
        return false
      }
    },
    reportSwitch () {
      if (this.reportEnabled === true || this.reportEnabled === 'true') {
        this.btnDisabledReportSchedule = false
        this.btnDisabledReportPath = false
        this.btnDisabledReportSave = false
      } else {
        this.btnDisabledReportSchedule = true
        this.btnDisabledReportPath = true
        // this.btnDisabledReportSave = true
      }
    },
    // get intial data
    getinitialdata () {
      this.$utils
        .callAxios(
          requests.getReportApi.method,
          requests.getReportApi.url
        )
        .then((res) => {
          this.getInitialData = res.data
          this.reportEnabled = JSON.parse(res.data.aimsProductReport)
          this.reportSchedule = res.data.aimsProductReportSchedule
          this.reportPath = res.data.aimsProductReportPath
          this.reportSwitch()
        })
    },
    // save event
    async saveReportSetting () {
      const storearray = []
      var params = ''
      if (this.reportEnabled !== JSON.parse(this.getInitialData.aimsProductReport)) {
        params = { key: 'AIMS_PRODUCT_REPORT', value: this.reportEnabled }
        storearray.push(params)
      }
      if (this.reportSchedule !== this.getInitialData.aimsProductReportSchedule) {
        if (this.reportSchedule < 30 || this.reportSchedule > 180) {
          EventBus.$emit('messageAlert', this.$t('Product Report File schedule time should be between 30 minutes to 180 minutes'))
          return
        }
        params = { key: 'AIMS_PRODUCT_REPORT_SCHEDULE', value: this.reportSchedule }
        storearray.push(params)
      } if (this.reportPath !== this.getInitialData.aimsProductReportPath) {
        params = { key: 'AIMS_PRODUCT_REPORT_PATH', value: this.reportPath.trim() }
        storearray.push(params)
      }
      if (storearray.length === 0) {
        EventBus.$emit('messageAlert', this.$t('There is no change in data'))
      }
      const ApiCallArray = []
      storearray.forEach((item) => {
        this.showErrorMessage = false
        const config = { params: item }
        ApiCallArray.push(
          this.$utils
            .callAxiosWithBody(
              requests.putReportApi.method,
              requests.putReportApi.url,
              {},
              config
            )
            .catch(error => {
              this.showErrorMessage = true
              console.debug(error)

              if (item.key === 'AIMS_PRODUCT_REPORT_PATH') {
                EventBus.$emit('messageAlert', this.$t('Product file path is incorrect'))
              }
            })
        )
      })
      await Promise.all(ApiCallArray).then(res => {
        if (this.showErrorMessage !== true) {
          if (res[0].status === 200) {
            EventBus.$emit('messageAlert', this.$t(res[0].data.responseMessage))
            this.getinitialdata()
          } else {
            EventBus.$emit('messageAlert', this.$t(res[0].data.responseMessage))
          }
        }
      }).catch(() => {
      })
    }
  },

  mounted () {
    this.getinitialdata()
  }
}
</script>
