<template>
  <div>
    <v-row>
      <v-col>
        <h3
          class="tit-group-bg mt-10"
          style="color: rgba(0, 0, 0, 0.6); line-height: 1"
        >
          {{ $t("Software Image Custom") }}
        </h3>
      </v-col>
    </v-row>
    <v-row class="label-txt" style="width:100%">
        <div class="" style="justify-content: normal; display: inline-flex">
        <v-col style="margin-top:12px" cols="3"> {{ $t("Login Main Image") }}</v-col>
        <v-col style="margin-top:12px;" cols="3"> Size(px) 960 x 937</v-col>
        <v-col style="margin-top:12px;" cols="3"> *.jpg,.png,.gif</v-col>
        <v-col style="margin-left:-3px">
        <v-file-input
        accept="image/png, image/jpeg, image/gif"
        :multiple="false"
            outlined
            dense
            hide-details
            prepend-icon
            solo
            flat
             style="width: 200px"
            :placeholder="`${$t('File Upload')}`"
            class="form-file"
            v-model="ImageInput.file_1"
            :clearable="false"
            @change="updateCustomerImage(1, 'update')"
          >
            <template v-slot:append>
                <v-icon v-if="CustomImage1!=''" style="color:red" @click="updateCustomerImage(1,'delete')"   medium>mdi-close-circle </v-icon>
<span v-if="CustomImage1===''" >
              <img
                width="24"
                height="24"
                src="@/assets/img/ico-upload-file.gif"
              /></span>
            </template>
          </v-file-input>
        </v-col>
        </div>
    </v-row>
    <v-row class="label-txt" style="width:100%">
        <div class="" style="justify-content: normal; display: inline-flex">
        <v-col style="margin-top:12px" cols="3"> {{ $t("Login Logo Image") }}</v-col>
        <v-col style="margin-top:12px" cols="3"> Size(px) 148 x 26</v-col>
        <v-col style="margin-top:12px" cols="3"> *.jpg,.png,.gif</v-col>
        <v-col >
        <v-file-input
         v-model="ImageInput.file_2"
        accept="image/png, image/jpeg, image/gif"
        :multiple="false"
            outlined
            dense
            hide-details
            prepend-icon
            solo
            flat
             style="width: 200px"
            :placeholder="`${$t('File Upload')}`"
            class="form-file"
            :clearable="false"
            @change="updateCustomerImage(2, 'update')"
          >
            <template v-slot:append>
                <v-icon v-if="CustomImage2!=''" style="color:red" @click="updateCustomerImage(2,'delete')"   medium>mdi-close-circle </v-icon>
<span v-if="CustomImage2===''" >
              <img
                width="24"
                height="24"
                src="@/assets/img/ico-upload-file.gif"
              /></span>
            </template>
          </v-file-input>
        </v-col>
        </div>
    </v-row>
    <v-row class="label-txt" style="width:100%">
        <div class="" style="justify-content: normal; display: inline-flex">
        <v-col style="margin-top:12px" cols="3">{{ $t("Menu Logo Image") }}</v-col>
        <v-col style="margin-top:12px" cols="3"> Size(px) 183 x 50</v-col>
        <v-col style="margin-top:12px" cols="3"> *.jpg,.png,.gif</v-col>
       <v-col >
        <v-file-input
        accept="image/png, image/jpeg, image/gif"
        :multiple="false"
            outlined
            dense
            hide-details
            prepend-icon
            solo
            flat
             style="width: 200px"
            :placeholder="`${$t('File Upload')}`"
            class="form-file"
            v-model="ImageInput.file_3"
            :clearable="false"
            @change="updateCustomerImage(3, 'update')"
          >
            <template v-slot:append>

            <v-icon v-if="CustomImage3!=''" style="color:red" @click="updateCustomerImage(3, 'delete')"    medium>mdi-close-circle </v-icon>
<span v-if="CustomImage3===''" ><img
                width="24"
                height="24"
                src="@/assets/img/ico-upload-file.gif"
              /></span>
            </template>
          </v-file-input>
        </v-col>
        </div>
    </v-row>
    <v-row>
        <div class="mt-5">
        <v-btn :disabled="saveBtnDisabled || btnDisabledDashboardSettings " @click="saveCustomerImages()" class="btn-s ml-2" text>{{ $t("Save") }}</v-btn>
        </div>
    </v-row>

</div>
</template>
<script>
import commons from '@/plugins/commons'
import configs from '@/plugins/configs'
import EventBus from '@/plugins/eventBus.js'
import FileUploader from '@/mixins/FileUploader'
const requests = {
  getCustomImageApi: {
    method: 'get',
    url: '/common/config/imageList'
  },
  postCustomImageApi: {
    method: 'PUT',
    url: '/common/config/imageList'
  }

}
export default {
  mixins: [FileUploader],
  data () {
    return {
      ImageInput: {
        file_1: null,
        file_2: null,
        file_3: null
      },
      CustomImage1: '',
      CustomImage2: '',
      CustomImage3: '',
      deleteFlag: false,
      imagePayload: { imageList: [] },
      saveBtnDisabled: true,
      // button disabled
      btnDisabledDashboardSettings: null
    }
  },
  methods: {
    // inital load data
    getcustomImage () {
      this.$store.dispatch('dataStore/updateMenuLogoImage', '')
      this.$store.dispatch('dataStore/updateLoginLogoImage', '')
      this.$store.dispatch('dataStore/updateLoginMainImage', '')
      const url = configs.ApiUrl + requests.getCustomImageApi.url
      this.$utils.callAxios(requests.getCustomImageApi.method, url).then(async (res) => {
        if (res.status === 200) {
          var getImageList = res.data.imageList
          for (var i = 0; i < getImageList.length; i++) {
            if (getImageList[i].location === 'LoginMainImage') {
              this.CustomImage1 = getImageList[i].fileName
              this.$store.dispatch('dataStore/updateLoginMainImage', getImageList[i].fileData)
              const blob = await fetch(getImageList[i].fileData)
                .then(response => response.blob())
                .then(blob => blob)
              this.ImageInput.file_1 = []
              this.ImageInput.file_1[0] = new File(
                [blob],
                getImageList[i].fileName || 'Current file'
              )
            } else if (getImageList[i].location === 'LoginLogoImage') {
              this.$store.dispatch('dataStore/updateLoginLogoImage', getImageList[i].fileData)
              this.CustomImage2 = getImageList[i].fileName
              const blob = await fetch(getImageList[i].fileData)
                .then(response => response.blob())
                .then(blob => blob)
              this.ImageInput.file_2 = []
              this.ImageInput.file_2[0] = new File(
                [blob],
                getImageList[i].fileName || 'Current file'
              )
            } else if (getImageList[i].location === 'MenuLogoImage') {
              this.CustomImage3 = getImageList[i].fileName
              this.$store.dispatch('dataStore/updateMenuLogoImage', getImageList[i].fileData)
              const blob = await fetch(getImageList[i].fileData)
                .then(response => response.blob())
                .then(blob => blob)
              this.ImageInput.file_3 = []
              this.ImageInput.file_3[0] = new File(
                [blob],
                getImageList[i].fileName || 'Current file'
              )
            }
          }
        } else {
          this.$store.dispatch('dataStore/updateMenuLogoImage', '')
          this.$store.dispatch('dataStore/updateLoginLogoImage', '')
          this.$store.dispatch('dataStore/updateLoginMainImage', '')
        }
      }).catch(error => {
        console.debug(error)
      })
    },
    // onchange for upload file
    async updateCustomerImage (nos, operation) {
      const imageArray = ['LoginMainImage', 'LoginLogoImage', 'MenuLogoImage']
      const location = imageArray[nos - 1]
      const i = this.imagePayload.imageList.findIndex(_item => _item.location === location)
      let constructArray = {
        location: location,
        fileData: '',
        fileName: ''
      }

      this.saveBtnDisabled = false
      if (operation === 'update') {
        if (this.ImageInput['file_' + nos] != null && this.ImageInput['file_' + nos].length > 0) {
          const file = await commons.getBase64withFormat(this.ImageInput['file_' + nos][0])
          constructArray = {
            location: location,
            fileData: file,
            fileName: this.ImageInput['file_' + nos][0].name
          }
        }
      } else if (operation === 'delete') {
        this.ImageInput['file_' + nos] = null
        this['CustomImage' + nos] = ''
      }

      if (i > -1) {
        this.imagePayload.imageList[i] = constructArray
      } else {
        this.imagePayload.imageList.push(constructArray)
      }
    },
    // save upload file
    saveCustomerImages () {
      const params = {}
      const url = configs.ApiUrl + requests.getCustomImageApi.url
      const config = { params }
      for (var i = 0; i < this.imagePayload.imageList.length; i++) {
        var uploadedFileName = this.imagePayload.imageList[i].fileName
        if (uploadedFileName !== '') {
          var getExtension = uploadedFileName.includes('.png') || uploadedFileName.includes('.gif') || uploadedFileName.includes('.jpg')
          if (getExtension === false) {
            EventBus.$emit('messageAlert', 'Please upload only *.png, *.jpg, *.gif file')
            return
          }
        }
      }
      this.$utils
        .callAxiosWithBody(
          requests.postCustomImageApi.method,
          url,
          this.imagePayload,
          config
        )
        .then((res) => {
          if (res.status === 200) {
            const result = res.data
            EventBus.$emit('messageAlert', this.$t(result.responseMessage))
            this.getcustomImage()
            this.imagePayload.imageList = []
            this.saveBtnDisabled = true
          } else {
            EventBus.$emit('messageAlert', this.$t('Failed to save data.'))
          }
        }).catch(error => {
          console.debug(error)
          EventBus.$emit('messageAlert', this.$t('Failed to save data.'))
        })
    }

  },
  mounted () {
    this.getcustomImage()
    this.$store.dispatch('auth/getDisabledBtn', '9100').then((flag) => {
      this.btnDisabledDashboardSettings = flag
    })
  }
}
</script>
