var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c(
              "h3",
              {
                staticClass: "tit-group-bg mt-10",
                staticStyle: { color: "rgba(0, 0, 0, 0.6)", "line-height": "1" }
              },
              [_vm._v(" " + _vm._s(_vm.$t("REPORT SETTINGS")) + " ")]
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "label-txt  max-100",
          staticStyle: { width: "100%", margin: "0", padding: "0px" }
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "col-3",
                  staticStyle: { "margin-top": "20px", "margin-left": "20px" }
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("Product Report")))])]
              ),
              _c(
                "v-col",
                { staticStyle: { "margin-left": "-10px" } },
                [
                  _c("v-switch", {
                    staticClass: "form-switch",
                    attrs: { inset: "", "hide-details": "", solo: "" },
                    on: {
                      change: _vm.reportSwitch,
                      keypress: _vm.handleKeyUpspl
                    },
                    model: {
                      value: _vm.reportEnabled,
                      callback: function($$v) {
                        _vm.reportEnabled = $$v
                      },
                      expression: "reportEnabled"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "col-3" }, [
                _c(
                  "p",
                  {
                    staticClass: "label-txt",
                    staticStyle: { "margin-top": "30px", "margin-left": "20px" }
                  },
                  [_vm._v(_vm._s(_vm.$t("Product Report Schedule")))]
                ),
                _c("br")
              ]),
              _c("v-col", { staticStyle: { "margin-left": "-10px" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "justify-content": "normal",
                      display: "inline-flex"
                    }
                  },
                  [
                    _c(
                      "v-row",
                      [
                        _c("v-text-field", {
                          staticClass: "form-input",
                          staticStyle: {
                            "max-width": "88px",
                            "margin-left": "10px"
                          },
                          attrs: {
                            disabled: _vm.btnDisabledReportSchedule,
                            type: "number",
                            outlined: "",
                            dense: "",
                            "hide-details": ""
                          },
                          on: { keypress: _vm.handleKeyUpspl },
                          model: {
                            value: _vm.reportSchedule,
                            callback: function($$v) {
                              _vm.reportSchedule = $$v
                            },
                            expression: "reportSchedule"
                          }
                        }),
                        _c("span", [
                          _c(
                            "p",
                            {
                              staticClass: "label-txt",
                              staticStyle: {
                                "font-weight": "bold",
                                "margin-left": "12px",
                                "margin-top": "30px"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("minutes")))]
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "col-3" }, [
                _c(
                  "p",
                  {
                    staticClass: "label-txt",
                    staticStyle: { "margin-left": "20px", "margin-top": "30px" }
                  },
                  [_vm._v(_vm._s(_vm.$t("Product Report Path")))]
                ),
                _c("br")
              ]),
              _c("v-col", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "justify-content": "normal",
                      display: "inline-flex"
                    }
                  },
                  [
                    _c(
                      "v-row",
                      [
                        _c("v-text-field", {
                          staticClass: "form-input",
                          staticStyle: { width: "300px" },
                          attrs: {
                            disabled: _vm.btnDisabledReportPath,
                            placeholder:
                              "" + _vm.$t("Set product file upload path"),
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.reportPath,
                            callback: function($$v) {
                              _vm.reportPath = $$v
                            },
                            expression: "reportPath"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-5", staticStyle: { "margin-left": "10px" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn-s ml-2",
                  attrs: { disabled: _vm.btnDisabledReportSave, text: "" },
                  on: {
                    click: function($event) {
                      return _vm.saveReportSetting()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }