var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c(
              "h3",
              {
                staticClass: "tit-group-bg mt-10",
                staticStyle: { color: "rgba(0, 0, 0, 0.6)", "line-height": "1" }
              },
              [_vm._v(" " + _vm._s(_vm.$t("Family Link Menu Settings")) + " ")]
            )
          ])
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "label-txt",
          staticStyle: { width: "100%", margin: "0", padding: "0px" }
        },
        [
          _c(
            "table",
            {
              staticClass: "tbl-custom-type01 max-100",
              attrs: { cellspacing: "0", cellpadding: "0" }
            },
            [
              _c("colgroup", [
                _c("col", { staticStyle: { width: "20%" } }),
                _c("col", { staticStyle: { width: "30%" } }),
                _c("col", { staticStyle: { width: "45%" } })
              ]),
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("Module Name")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("Module Path")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("On/Off")))])
                ])
              ]),
              _c("tbody", [
                _c("tr", { staticStyle: { "vertical-align": "baseline" } }, [
                  _c("td", [_vm._v(" " + _vm._s(_vm.$t("LD")) + " ")]),
                  _c(
                    "td",
                    [
                      _c("v-text-field", {
                        attrs: { outlined: "", "hide-details": "", dense: "" },
                        model: {
                          value: _vm.layOutDesigner.path,
                          callback: function($$v) {
                            _vm.$set(_vm.layOutDesigner, "path", $$v)
                          },
                          expression: "layOutDesigner.path"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c("v-switch", {
                        staticClass: "form-switch",
                        attrs: { inset: "", "hide-details": "", solo: "" },
                        model: {
                          value: _vm.layOutDesigner.used,
                          callback: function($$v) {
                            _vm.$set(_vm.layOutDesigner, "used", $$v)
                          },
                          expression: "layOutDesigner.used"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("tr", { staticStyle: { "vertical-align": "baseline" } }, [
                  _c("td", [_vm._v(" " + _vm._s(_vm.$t("PDA")) + " ")]),
                  _c(
                    "td",
                    [
                      _c("v-text-field", {
                        attrs: { outlined: "", "hide-details": "", dense: "" },
                        model: {
                          value: _vm.webPDA.path,
                          callback: function($$v) {
                            _vm.$set(_vm.webPDA, "path", $$v)
                          },
                          expression: "webPDA.path"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c("v-switch", {
                        staticClass: "form-switch",
                        attrs: { inset: "", "hide-details": "", solo: "" },
                        model: {
                          value: _vm.webPDA.used,
                          callback: function($$v) {
                            _vm.$set(_vm.webPDA, "used", $$v)
                          },
                          expression: "webPDA.used"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("h5", { staticClass: "APIDocClass" }, [
                  _c("th", [_vm._v(_vm._s(_vm.$t("API DOCS")))])
                ]),
                _c("tr", { staticStyle: { "vertical-align": "baseline" } }, [
                  _c("td", [_vm._v(" " + _vm._s(_vm.$t("DasboardWeb")) + " ")]),
                  _c(
                    "td",
                    [
                      _c("v-text-field", {
                        attrs: { outlined: "", "hide-details": "", dense: "" },
                        model: {
                          value: _vm.dashboardService.path,
                          callback: function($$v) {
                            _vm.$set(_vm.dashboardService, "path", $$v)
                          },
                          expression: "dashboardService.path"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c("v-switch", {
                        staticClass: "form-switch",
                        attrs: { inset: "", "hide-details": "", solo: "" },
                        model: {
                          value: _vm.dashboardService.used,
                          callback: function($$v) {
                            _vm.$set(_vm.dashboardService, "used", $$v)
                          },
                          expression: "dashboardService.used"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("tr", { staticStyle: { "vertical-align": "baseline" } }, [
                  _c("td", [_vm._v(" " + _vm._s(_vm.$t("Portal")) + " ")]),
                  _c(
                    "td",
                    [
                      _c("v-text-field", {
                        attrs: { outlined: "", "hide-details": "", dense: "" },
                        model: {
                          value: _vm.portal.path,
                          callback: function($$v) {
                            _vm.$set(_vm.portal, "path", $$v)
                          },
                          expression: "portal.path"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c("v-switch", {
                        staticClass: "form-switch",
                        attrs: { inset: "", "hide-details": "", solo: "" },
                        model: {
                          value: _vm.portal.used,
                          callback: function($$v) {
                            _vm.$set(_vm.portal, "used", $$v)
                          },
                          expression: "portal.used"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ]
      ),
      _c("v-row", [
        _c(
          "div",
          { staticClass: "mt-5", staticStyle: { "margin-left": "21px" } },
          [
            _c(
              "v-btn",
              {
                staticClass: "btn-s ml-2",
                attrs: { disabled: _vm.btnDisabledDashboardSettings, text: "" },
                on: {
                  click: function($event) {
                    return _vm.saveAPIPath()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("Save")))]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }